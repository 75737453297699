import Vue from 'vue'
import VueResource from 'vue-resource';
import App from './App.vue'
import config from './config.js'
import vueConfig from 'vue-config';


Vue.config.productionTip = false
Vue.use(VueResource);
Vue.use(vueConfig, config);

new Vue({
  render: h => h(App),
}).$mount('#app')
