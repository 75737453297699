<template>
  <table align="center">
    <tr>
      <td>
        <img src="../assets/edt80.png" />
      </td>
      <td class="name">
        <div class="header">{{ getEnvironment() }}</div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    getEnvironment() {
      var tokens = window.location.toString().split('//');

      var environment = tokens[1];
      if (environment.includes('/')) 
      {
        return environment.split('/')[0];
      } else 
      {
        return environment;
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  font-size: 30px;
}

.name {
  padding-left: 50px;
}
</style>
