<template>
  <div>
    <div id="notStarted" v-if="!starting">
      <p class="info">
        The requested environment is currently in a
        <b>sleep</b> state. <br /><br />
        Click below to start it.
        <br />
        <br />
        <button v-on:click="startEnvironment" class="startbutton">
          <img
            alt="click to start"
            src="../assets/power_button.png"
            class="startbuttonimg"
          />
        </button>
        <br /><br />
        <br />
        Please note, it will take around <b>2 minutes</b> to start the
        environment.
      </p>
    </div>
    <div id="starting" v-if="starting">
      <img
        alt="environment starting"
        src="../assets/starting.gif"
        class="startingimg"
      />
      <div v-if="!errorMessage">
        <p>The environment is starting, please wait...</p>
        <br />
        <p>
          This page will automatically redirect<span v-if="countdown >= 0">
            in approx. {{ countdown }} seconds</span
          >
        </p>
      </div>
      <p v-if="errorMessage" class="error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StateChange',
  data() {
    return {
      starting: false,
      timer: '',
      countdown: -1,
      errorMessage: null,
      pollTime: 120,
    };
  },
  mounted() {
    this.region = this.$config.REGION;
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--;
          }, 1000);
        } else if (value == 0) {
          window.location.reload();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getEnvironment() {
      // return 'qarelease';
      var tokens = window.location.toString().split('//');

      var environment = tokens[1];
      if (environment.includes('/')) {
        environment = environment.split('/')[0];
      }

      return environment.split('.')[0];
    },
    getCheckUrl() {
      //var url = window.location.origin;
      var url = 'https://qarelease.edtdev.blue/';
      if (url.endsWith('/') || !url.replace('://', '').includes('/')) {
        url = `${url}${url.endsWith('/') ? '' : '/'}Account/Login.aspx`;
      }
      console.log(`check url is ${url}`);
      return url;
    },
    startEnvironment() {
      this.starting = true;
      var environment = this.getEnvironment();
      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'DELETE, PUT, GET, OPTIONS',
        },
      };
      this.$http
        .put(
          `${this.$config.API_URL}/DemoServer/Start?environment=${environment}`,
          config
        )
        .then(function(response) {
          if (response.ok) {
            this.timer = setInterval(this.isServerStarted, 5000);
            this.countdown = this.pollTime;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.status == 404){
            this.errorMessage =
              `No stopped instances found for ${environment}, it may already be starting. Please try again later.`;
          } else {
            this.errorMessage =
              'Failed to start server, please contact EDT Support.';
          }
        });
    },
    isServerStarted() {
      console.log('checking if route 53 updated...');
      this.$http
        .get(this.getCheckUrl())
        .then(function(response) {
          console.log(response.body);
          if (response.body.includes('<title>EDT-Sleeping</title>')) {
            console.log('still starting');
          } else {
            console.log('shouldnt be on this page still');
            window.location.reload();
          }
        })
        .catch(error => {
          console.log('error so likely still starting');
          console.log(error);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.startbutton {
  border: none;
  background-color: transparent;
}
.startbuttonimg {
  height: 150px;
  width: 150px;
}

.startingimg {
  height: 300px;
  width: 300px;
}

.error {
  color: red;
  font-weight: bold;
}
</style>
